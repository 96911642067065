<template>
  <div>
    <v-container fluid class="mt-5">
      <v-row justify="center" class="my-3">
        <v-btn
          dense
          x-small
          v-for="admin in admins"
          :key="admin"
          :disabled="search == admin"
          class="mx-1 mb-2 px-2"
          @click="search = admin"
        >
          {{ admin }}
        </v-btn>
      </v-row>
      <v-card>
        <v-card-title>
          <b> Applicants Information</b>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="applicants"
          :search="search"
          :custom-filter="filterApplicants"
          show-expand
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              color="error"
              x-small
              @click="del(item)"
              title="Click to delete this record"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template #item.createdAt="{ value }">
            {{ value | formatDate }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="my-6">
                <!-- Send Email to Individual Participants -->
                <!-- <v-btn
                  color="success"
                  :loading="loading"
                  @click="sendMail(item, 'single')"
                >
                  Send Email
                </v-btn> -->
                <mdev-details :item="item" :save-changes="saveChanges" />
                <v-row no-gutters>
                  <v-col
                    v-for="action in actions"
                    :key="`action-${action}`"
                    cols="auto"
                    class="my-0 py-0 mx-2"
                  >
                    <v-switch
                      v-model="item[action]"
                      :label="action"
                      :false-value="0"
                      :true-value="1"
                      @change="saveChanges(item, action)"
                    ></v-switch>
                  </v-col>
                  <v-col cols="auto" class="my-0 py-0 mx-2">
                    <v-text-field
                      v-model.number="item.attendance"
                      label="Attendance"
                      type="number"
                      @change="saveChanges(item, 'attendance')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto" class="my-0 py-0 mx-2">
                    <v-text-field
                      v-model="item.payment"
                      label="Payment"
                      @change="saveChanges(item, 'payment')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="auto" class="my-0 py-0 mx-2">
                    <v-textarea
                      v-model="item.remark"
                      label="Remark"
                      dense
                      auto-grow
                      rows="1"
                      @change="saveChanges(item, 'remark')"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card>
      <vue-excel-xlsx
        class="text-center mt-2"
        :data="applicants"
        :columns="excelHeaders"
        file-name="mdev-applicants"
        file-type="xlsx"
        sheet-name="applicants"
      >
        <v-btn small rounded class="white--text" color="primary">
          Export to Excel</v-btn
        >
      </vue-excel-xlsx>
    </v-container>

    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from "@/mixins";
import { apiClient } from "@/services";
import MdevDetails from "@/components/MdevDetails.vue";
export default {
  name: "MdevInfo",
  mixins: [snackMixin],
  components: {
    MdevDetails,
  },
  data() {
    return {
      expanded: [],
      search: "",
      headers: [
        { text: "Name of Beneficiary", value: "fullname"},
        { text: "Gender", value: "gender" },
        { text: "Age Grade", value: "age" },
        { text: "Phone Number", value: "phone" },
        { text: "Current Address ", value: "address" },
        // { text: "State of Origin", value: "ostate" },
        // { text: "LGA of Origin", value: "lga" },
        { text: "Experience", value: "experience" },
        { text: "Qualification", value: "qualification" },
        { text: "Center", value: "trainingCenter" },
        { text: "Laptop", value: "laptop" },
        { text: "Created Date", value: "createdAt" },
        { text: "", value: "data-table-expand" },
        { text: "", value: "actions" },
      ],
      excelHeaders: [
        { label: "Name of Beneficiary", field: "fullname"},
        { label: "Gender", field: "gender" },
        { label: "Age Grade", field: "age" },
        { label: "Phone Number", field: "phone" },
        { label: "Email ", field: "email" },
        { label: "Current Address ", field: "address" },
        { label: "State of Origin", field: "ostate" },
        { label: "LGA of Origin", field: "lga" },
        { label: "Experience", field: "experience" },
        { label: "Qualification", field: "qualification" },
        { label: "Center", field: "trainingCenter" },
        { label: "Laptop", field: "laptop" },
        { label: "Shortlisted", field: "shortlisted" },
        { label: "admitted", field: "admitted" },
        { label: "resumed", field: "resumed" },
        { label: "attendance", field: "attendance" },
        { label: "Date Created", field: "createdAt" },
      ],
      admins: [
        "shortlisted",
        "admitted",
        "resumed",
        "payment",
        "attendance",
        "ido",
        "ado",
        "ikole",
      ],
      actions: [
        "shortlisted",
        "admitted",
        "resumed",
      ],
      applicants: [],
    };
  },

  async created() {
    this.getRecords();
  },

  methods: {
    async getRecords() {
      this.loading = true;
      try {
        const res = await apiClient.get("/mdev?u=2");
        if (Array.isArray(res.data)) {
          this.applicants = res.data;
        } else {
          this.displayMsg("Error Occurred:" + res.data, "error");
        }
      } catch (err) {
        this.displayMsg("Error Occurred:" + err.message, "error");
      }
      this.loading = false;
    },

    del(item) {
      const ok = confirm(
        `Are sure you want to delete ${item.fullname} records?`
      );
      if (ok) {
        this.displayMsg(`${item.fullname}, please wait...`);
        apiClient
          .delete(`/mdev?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(
                `${item.fullname} not successfully deleted`,
                "error"
              );
              return;
            } else {
              this.applicants = this.applicants.filter(
                (pt) => pt.id !== item.id
              );
              this.displayMsg(`${item.fullname} successfully deleted `);
            }
          })
          .catch((err) => this.displayMsg("Error Occurred:" + err.message, "error"));
      }
    },

    async saveChanges(item, type) {
      this.loading = true;
      item.type = type;
      try {
        const res = await apiClient.put("/mdev", item);
        if (res.data.ok) {
          this.displayMsg("Changes saved successfully");
        } else {
          this.displayMsg("Error Occurred:" + res.data, "error");
        }
      } catch (err) {
        this.displayMsg("Error Occurred:" + err.message, "error");
      }
    },
    formatAge(value) {
      const age = new Date().getFullYear() - new Date(value).getFullYear();
      return `${value}:${age}`;
    },
    filterApplicants(value, search, item) {
      switch (search) {
        case "shortlisted":
          return !!item.shortlisted;
        case "admitted":
          return !!item.admitted;
        case "resumed":
          return !!item.resumed;
        case "mailed":
          return !!item.mailed;
        case "payment":
          return item.payment != 0.0;
        case "attendance":
          return item.attendance != 0;
        case "ido":
          return item.trainingCenter == "Ido-Ekiti";
        case "ado":
          return item.trainingCenter == "Ado-Ekiti";
        case "ikole":
          return item.trainingCenter == "Ikole-Ekiti";
        default:
          return (
            value != null &&
            search != null &&
            value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
          );
      }
    },
  },
};
</script>
