<template>
  <div class="white">
    <v-alert
      type="info"
      icon="mdi-checkbox-multiple-marked-outline"
      border="left"
      class="my-0"
      tile
    >
      <h5 class="text-center">Free Mobile Application Development Training</h5>
    </v-alert>
    <!-- <v-col cols="12" class="black--text text-center" v-if="applicationClosed">
        <p><b>The application has closed.</b></p>
        <p>If you are interested in the digital skill training, kindly visit our office:</p>
        <p>
          Top Floor, 68B Christore Building, <br />Opp. Crunchies Restaurant,<br />
          Similoluwa, Ado Ekiti, Ekiti State, Nigeria.<br />
          Phone: +2348071572767</p>
      </v-col>      -->
    <v-container v-if="!submitted">
      <h5 class="text-center mb-2">
        <b>Mon 20 - Fri 24 Nov, 2023 <br /></b>
        <b class="error--text">All fields are required.</b>
      </h5>
      <v-form ref="dform" lazy-validation @submit.prevent>
        <v-row class="mt-2">
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="dform.fullname"
              label="Full Name:"
              :rules="nameRules"
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model.trim="dform.phone"
              maxlength="15"
              type="number"
              :rules="phoneRules"
              label="Mobile Number:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              type="email"
              :rules="emailRules"
              v-model.trim="dform.email"
              label="E-mail:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.gender"
              :items="genders"
              :rules="messageRules"
              label="Gender:"
              outlined
              dense
              required
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.age"
              :rules="messageRules"
              label="Age Range:"
              outlined
              dense
              :items="ageRange"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.qualification"
              :rules="messageRules"
              label="Highest Educational Qualification:"
              outlined
              dense
              :items="qualifications"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.experience"
              :rules="messageRules"
              label="Experience with Coding:"
              outlined
              dense
              :items="experienceLevel"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.trainingCenter"
              :rules="messageRules"
              label="Preferred Training Center:"
              outlined
              dense
              :items="trainingCenters"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.ostate"
              :rules="messageRules"
              label="State of Origin:"
              required
              outlined
              dense
              :items="ngstates"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model.trim="dform.lga"
              :rules="messageRules"
              label="LGA of Origin e.g Ado-Ekiti"
              type="text"
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model.trim="dform.address"
              :rules="messageRules"
              label="Current Address"
              type="text"
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-radio-group v-model="dform.laptop" :rules="messageRules" row>
              <span>Do you have a laptop?</span>
              <v-radio
                label="Yes"
                color="success"
                value="Yes"
                class="mx-2"
              ></v-radio>
              <v-radio label="No" color="red" value="No" class="mx-2"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-form>

      <v-row justify="center" class="pa-5">
        <v-spacer></v-spacer>

        <v-btn
          tile
          color="success"
          :loading="loading"
          @click="submitApplication"
          :disabled="!isValid"
        >
          Submit Application
        </v-btn>
      </v-row>
    </v-container>

    <v-row class="ma-1 px-2 px-sm-1" v-else>
      <v-col cols="12">
        <h5>
          You have successfully submitted your application. Follow Kinplus
          Technologies and NITDA on social media platforms.
        </h5>
        <v-btn @click="initializePage" color="primary">close</v-btn>
      </v-col>
    </v-row>
    <!-- </v-alert> -->

    <v-snackbar v-model="snackbar" :color="snackColor" :timeout="4000">
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-alert
      type="info"
      icon="mdi-checkbox-multiple-marked-outline"
      border="left"
      class="my-0"
      tile
    >
      <h4 class="text-center">
        <b
          >Partners: <br />
          <img src="img/nitda-logo-cropped.png" alt="NITDA" width="100" />

          <br />Akintunde Oyebode</b
        >
      </h4>
    </v-alert>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, rulesMixin } from "@/mixins";
import { mapState } from "vuex";
export default {
  name: "MDev",
  mixins: [snackMixin, rulesMixin],
  data() {
    return {
      applicationClosed: false,
      submitted: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      // "Virtual/Online",
      trainingCenters: ["Ado-Ekiti", "Ido-Ekiti", "Ikole-Ekiti"],
      qualifications: [
        "Post Graduate",
        "Bachelor",
        "HND",
        "ND",
        "NCE",
        "SSCE",
        "Others",
      ],
      ageRange: ["16-20", "21-30", "31-50", "50+"],
      experienceLevel: ["No Experience", "Beginner", "Intermediate"],
      dform: this.initializeForm(),
    };
  },
  computed: {
    ...mapState(["genders", "ngstates"]),
    isValid() {
      const reqf = [
        "fullname",
        "phone",
        "email",
        "gender",
        "age",
        "qualification",
        "trainingCenter",
        "laptop",
        "ostate",
        "lga",
      ];
      return reqf.every((v) => !!this.dform[v]);
    },
  },
  methods: {
    closeForm() {
      this.dform = this.initializeForm();
    },
    initializePage() {
      this.dform = this.initializeForm();
      this.submitted = false;
    },
    submitApplication() {
      this.loading = true;
      apiClient
        .post("/mdev", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.submitted = true;
            this.displayMsg("Application submitted Succesfully.");
            this.closeForm();
          } else {
            this.displayMsg("Error Occured: " + res.data, "error");
          }
        })
        .catch((error) => {
          this.displayMsg("Error Occured: " + error.message, "error");
        });
    },

    saveChanges() {
      this.loading = true;
      apiClient
        .put("/mdev", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes Saved successfully");
            this.closeForm();
          }
        })
        .catch((error) => {
          this.displayMsg("Error Occured: " + error.message, "error");
        });
    },
    initializeForm() {
      return {
        fullname: "",
        phone: "",
        email: "",
        gender: "",
        age: "",
        qualification: "",
        trainingCenter: "",
        laptop: "",
        ostate: "",
        lga: "",
        address: "",
      };
    },
  },
};
</script>
