<template>
  <div class="white">
    <v-alert
      type="info"
      icon="mdi-checkbox-multiple-marked-outline"
      border="left"
      class="my-0"
      tile
    >
      <h4 class="text-center">Digital Skill Training & Internship for Youths in Ekiti State</h4>
      <h5 class="text-center">Online & Onsite</h5>
    </v-alert>
    <v-container v-if="!submitted">
      <p class="text-center">
        <!-- <b>Mon 20 - Fri 24 Nov, 2023 <br /></b> -->        
        <b>Partner With Us</b><br>
        <b class="error--text">Kindly fill the fields.</b>
      </p>
      <v-form ref="dform" lazy-validation @submit.prevent>
        <v-row class="mt-2">
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model="dform.fullname"
              label="Full Name:"
              :rules="nameRules"
              outlined
              dense
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model.trim="dform.phone"
              maxlength="15"
              type="number"
              :rules="phoneRules"
              label="Mobile Number:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              type="email"
              :rules="emailRules"
              v-model.trim="dform.email"
              label="E-mail:"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model.trim="dform.organization"
              label="Organization/Company Name"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.partnership"
              :items="partnerships"
              :rules="messageRules"
              label="Type of Partnership:"
              outlined
              dense
              required
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-text-field
              v-model.trim="dform.amount"
              label="Monetary Support Amount (NGN)"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-select
              v-model="dform.donation"
              :items="donations"
              label="Type of Monetary Donation:"
              outlined
              dense
              required
            >
            </v-select>
          </v-col>
          
        </v-row>
      </v-form>

      <v-row justify="center" class="pa-5">
        <v-spacer></v-spacer>

        <v-btn
          tile
          color="success"
          :loading="loading"
          @click="submitApplication"
        >
          Submit Application
        </v-btn>
      </v-row>
    </v-container>

    <v-row class="ma-1 px-2 px-sm-1" v-else>
      <v-col cols="12">
        <h5>
          Form submitted successfully. Kindly check your email.
        </h5>
        <v-btn @click="initializePage" color="primary">close</v-btn>
      </v-col>
    </v-row>
    <!-- </v-alert> -->

    <v-snackbar v-model="snackbar" :color="snackColor" :timeout="4000">
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-alert
      type="info"
      icon="mdi-checkbox-multiple-marked-outline"
      border="left"
      class="my-0"
      tile
    >
      <h4 class="text-center">
        Sponsor: Akintunde Oyebode <br />
        Partner: <img src="img/nitda-logo-cropped.png" alt="NITDA" width="100" />
          <!-- <br />Orolu Oladayo -->
      </h4>
    </v-alert>
  </div>
</template>

<script>
import { apiClient } from "@/services";
import { snackMixin, rulesMixin } from "@/mixins";
export default {
  name: "Partner",
  mixins: [snackMixin, rulesMixin],
  data() {
    return {
      applicationClosed: false,
      submitted: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      partnerships: [
        "Monetary Support",
        "Service-Based Support",
        "Combination of Both"
      ],
      donations: [
        "One-Time Donation",
        "Periodic Donation"
      ],
      dform: this.initializeForm(),
    };
  },
  computed: {
    showNYSC(){
      return this.dform.qualification =="Bachelor" || this.dform.qualification =="HND"
    },
    isValid() {
      const reqf = [
        "fullname",
        "phone",
        "email",
        "partnership",
      ];
      return reqf.every((v) => !!this.dform[v]);
    },
  },
  methods: {
    closeForm() {
      this.dform = this.initializeForm();
    },
    initializePage() {
      this.dform = this.initializeForm();
      this.submitted = false;
    },
    submitApplication () {
      if ( !this.isValid ) {
        this.displayMsg( "Kindly fill all the fields. ", "error" )
        return
      }
      this.loading = true;
      apiClient
        .post("/partner", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.submitted = true;
            this.displayMsg("Application submitted Succesfully.");
            this.closeForm();
          } else {
            this.displayMsg( "Error Occured: " + res.data, "error" );
          }
        })
        .catch((error) => {
          this.displayMsg( "Error Occured: " + error.message, "error" );
        });
    },

    saveChanges() {
      this.loading = true;
      apiClient
        .put("/partner", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.displayMsg("Changes Saved successfully");
            this.closeForm();
          }
        })
        .catch((error) => {
          this.displayMsg("Error Occured: " + error.message, "error");
        });
    },
    initializeForm() {
      return {
        fullname: "",
        phone: "",
        email: "",        
        partnership: "",
      };
    },
  },
};
</script>
