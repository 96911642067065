<template>
  <div class="white">
    <v-alert
      type="info"
      icon="mdi-checkbox-multiple-marked-outline"
      border="left"
      class="my-0"
      tile
    >
      <h4 class="text-center">Digital Skill Training & Internship for Youths in Ekiti State</h4>
      <h5 class="text-center">Online & Onsite</h5>
    </v-alert>
    <v-container v-if="!submitted">
      
        <v-row class="mt-2">
          <v-col cols="12" md="6" lg="4">
            For Talent
          </v-col>
          <v-col cols="12" md="6" lg="4">
            Come Partner With Us
          </v-col>
          </v-row>
          

      <v-row justify="center" class="pa-5">
        <v-spacer></v-spacer>

        <v-btn
          tile
          color="success"
          :loading="loading"
          @click="submitApplication"
        >
          Submit Application
        </v-btn>
      </v-row>
    </v-container>
    <!-- </v-alert> -->

    
    <v-alert
      type="info"
      icon="mdi-checkbox-multiple-marked-outline"
      border="left"
      class="my-0"
      tile
    >
      <h4 class="text-center">
        Sponsor: Akintunde Oyebode <br />
        Partner: <img src="img/nitda-logo-cropped.png" alt="NITDA" width="100" />
          <!-- <br />Orolu Oladayo -->
      </h4>
    </v-alert>
  </div>
</template>

<script>
import { snackMixin, rulesMixin } from "@/mixins";
export default {
  name: "TechUp",
  mixins: [snackMixin, rulesMixin],
  data() {
    return {
      applicationClosed: false,
      submitted: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },      
      dform: this.initializeForm(),
    };
  },
  methods: {
    closeForm() {
      this.dform = this.initializeForm();
    },
    initializePage() {
      this.dform = this.initializeForm();
      this.submitted = false;
    },    
  },
};
</script>
