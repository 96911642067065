<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-bold text-center primary--text">
          Applicant Details
        </h3>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="item.email"
          label="Email: "
          @change="saveChanges(item, 'email')"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">State: </span>
        {{ item.rstate }}
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">LGA: </span>
        {{ item.lga }}
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Qualification: </span>
        {{ item.qualification }}
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">NYSC Completed: </span>
        {{ item.nyscCompleted }}
      </v-col>
      
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Experience: </span>
        {{ item.experience }}
      </v-col>
      
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Training Center: </span>
        {{ item.trainingCenter }}
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Laptop: </span>
        {{ item.laptop }}
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Internet: </span>
        {{ item.internet }}
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Current Address: </span>
        {{ item.address }}
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from "@/mixins";

export default {
  name: "IkereDetails",
  mixins: [snackMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    saveChanges: Function,
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
    };
  },
};
</script>
